import { License } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function license__server__getLicenseNoAuth(p: { licenseId: string }): Promise<License | null> {
  // SERVER_ONLY_TOGGLE
  if (p.licenseId === "") {
    return null;
  }
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let res = await h.License.getDoc(p.licenseId);
  return res;
  // SERVER_ONLY_TOGGLE
}

license__server__getLicenseNoAuth.auth = async () => {};

// i18n certified - complete
