import { License } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";

export async function license__server__getLicensesNoAuth(p: { licenseIds: string[] }): Promise<License[]> {
  // SERVER_ONLY_TOGGLE
  if (!p.licenseIds.length) {
    return [];
  }
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let res = await h.License.getDocs(p.licenseIds);
  return _.compact(res);
  // SERVER_ONLY_TOGGLE
}

license__server__getLicensesNoAuth.auth = async () => {};

// i18n certified - complete
