import { License } from "@ollie-sports/models";
import moment from "moment";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { addManualLicense } from "../../internal-utils/license-utils";
import { emitMarketingEvent } from "../../utils";

export async function license__server__addTrialLicense(p: { accountId: string }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const now = Date.now();

  const acountPrivate = await h.AccountPrivate.getDoc(p.accountId);

  if (!acountPrivate || !!acountPrivate.userInteractions?.hasUsedAccountTrial) {
    return null;
  }

  const expireAtMS = moment(now).add("14", "day").valueOf();

  const tasks = await addManualLicense({
    entityType: "account",
    actionPerformedByAccountId: p.accountId,
    entityId: p.accountId,
    startAtMS: now,
    expireAtMS,
    isTrial: true
  });

  tasks.push(await h.AccountPrivate.update({ id: p.accountId, doc: { userInteractions: { hasUsedAccountTrial: true } } }));

  await h._BatchRunner.executeBatch(tasks);

  await emitMarketingEvent({
    olliePipe,
    event: {
      type: "AccountTrial",
      accountId: p.accountId
    }
  }).catch((e: any) => {
    console.error(e);
  });

  return expireAtMS;
  // SERVER_ONLY_TOGGLE
}

license__server__addTrialLicense.auth = async () => {};
// i18n certified - complete
