import { License } from "@ollie-sports/models";
import { BifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftDocSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function license__client__getSubscription(p: { licenseId: string }): BifrostSubscription<License | null> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocSubToBifrostSub(h.License.docSubscription(p.licenseId));
}

// i18n certified - complete
